import { featureKeys as leadFeatureKeys } from '@/api/leads/leads';
import { useUpdateQuote } from '@/api/leads/quotes/updateQuote';
import { FormCheckbox, FormInput, FormTextarea } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { queryClient } from '@/lib/react-query';
import { Quote } from '@/types';
import { formatDate } from '@/utils/format';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface MultiQuoteEditFormProps {
  quote: Quote;
}

const schema = z.object({
  expires_at: z.string().nullish(),
  is_bookable_online: z.boolean(),
  customer_message: z.string().nullish(),
  internal_notes: z.string().nullish(),
});

export const MultiQuoteEditForm = ({ quote }: MultiQuoteEditFormProps) => {
  const { mutate: updateQuote } = useUpdateQuote();

  const handleSubmit = (data: Partial<Quote>) => {
    updateQuote(
      { id: quote?.id, data },
      {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: leadFeatureKeys.details() }),
      },
    );
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      expires_at: quote.expires_at ? formatDate(quote.expires_at, 'yyyy-MM-dd') : '',
      is_bookable_online: quote.is_bookable_online ?? true,
      customer_message: quote.customer_message ?? '',
      internal_notes: quote.internal_notes ?? '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="@container">
        <div className="py-4 px-1 flex flex-col space-y-3">
          <FormCheckbox control={form.control} name="is_bookable_online" label="Bookable Online" />
          <FormInput control={form.control} type="date" name="expires_at" label="Expires on..." />
          <FormTextarea
            control={form.control}
            name="customer_message"
            label="Message to Customer"
            className="resize-y overflow-y-scroll"
          />
          <FormTextarea
            control={form.control}
            name="internal_notes"
            label="Internal Notes"
            className="resize-y overflow-y-scroll"
          />
        </div>
        <div className="flex flex-row">
          <Button size="sm" variant="outline" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
};
