import { useCreateCustomer } from '@/api/customers/customers/createCustomer';
import { useUpdateCustomer } from '@/api/customers/customers/updateCustomer';
import { featureKeys as leadFeatureKeys } from '@/api/leads/leads';
import { FormInput } from '@/components/Form';
import { FormEmailInput } from '@/components/Form/FormEmailInput';
import { FormPhoneInput } from '@/components/Form/FormPhoneInput';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { queryClient } from '@/lib/react-query';
import { Customer, Lead, ModelID } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface BasicCustomerFormProps {
  lead: Lead;
  className?: string;
}

const schema = z.object({
  first_name: z.string().min(1, 'Required'),
  last_name: z.string(),
  phone: z.string().min(1, 'Required'),
  email: z.string(),
});

export const BasicCustomerForm = ({ lead }: BasicCustomerFormProps) => {
  const { updateLead, setSimilarLookupValues } = useContext(LeadContext);

  const { mutate: updateCustomer } = useUpdateCustomer();
  const { mutate: createCustomer } = useCreateCustomer();

  const handleCustomerSubmit = (data: Partial<Customer>) => {
    if (lead.customer_id) {
      updateCustomer(
        { id: lead?.customer_id, data },
        {
          onSuccess: () => queryClient.invalidateQueries({ queryKey: leadFeatureKeys.details() }),
        },
      );
    } else {
      createCustomer(
        { location: lead.franchise_id, data },
        {
          onSuccess: (customer) => {
            handleLinkCustomer(customer.id);
          },
        },
      );
    }
  };

  const handleFindSimilar = () => {
    const data = form.getValues();
    const search: Partial<Customer> = {};

    if (data.first_name) {
      search.first_name = data.first_name;
    }
    if (data.last_name) {
      search.last_name = data.last_name;
    }
    if (data.phone) {
      search.phone = data.phone;
    }
    if (data.email) {
      search.email = data.email;
    }

    setSimilarLookupValues(search);
  };

  const handleLinkCustomer = (customer_id: ModelID) => {
    updateLead({
      id: lead.id,
      data: {
        customer_id,
      },
    });
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      phone: lead.customer?.phone ?? lead.phone ?? '',
      email: lead.customer?.email ?? lead.email ?? '',
      first_name: lead.customer?.first_name ?? lead.first_name ?? '',
      last_name: lead.customer?.last_name ?? lead.last_name ?? '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleCustomerSubmit)} className="@container">
        <div className="py-4 px-1 grid gap-3 grid-cols-1 @sm:grid-cols-2">
          <FormInput
            control={form.control}
            name="first_name"
            label="First Name"
            onFieldBlur={handleFindSimilar}
          />
          <FormInput
            control={form.control}
            name="last_name"
            label="Last Name"
            onFieldBlur={handleFindSimilar}
          />
          <FormPhoneInput
            control={form.control}
            name="phone"
            label="Phone Number"
            phoneType={lead.customer?.phone_type}
            phoneValid={lead.customer?.phone_valid !== 0}
            onFieldBlur={handleFindSimilar}
          />
          <FormEmailInput
            control={form.control}
            name="email"
            label="Email Address"
            onFieldBlur={handleFindSimilar}
          />
        </div>
        <div className="flex flex-row justify-end">
          <Button size="sm" variant={form.formState.isDirty ? 'default' : 'outline'} type="submit">
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
};
