import { EventManageQuoteDTO } from '@/api/events/events/updateEvent';
import { LeadManageQuoteDTO } from '@/api/leads/leads/updateLead';
import { useAddons } from '@/api/locations/addons/getAddons';
import { usePackages } from '@/api/locations/packages/getPackages';
import { useServices } from '@/api/locations/services/getServices';
import { Placeholder } from '@/components/Elements';
import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { queryClient } from '@/lib/react-query';
import { Event, EventLineItem, Lead, LeadLineItem, LineItem, ModelID } from '@/types';
import { formatMoney } from '@/utils/format';
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckedState } from '@radix-ui/react-checkbox';
import { UseMutateFunction } from '@tanstack/react-query';
import { FocusEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormInput } from '../Form';
import { Checkbox } from '../ui/elements/checkbox';
import { Form } from '../ui/form/form';
import { ActivityRow } from './ActivityRow';
import { CouponRow } from './CouponRow';
import { LineItemRow } from './LineItemRow';

type QuoteBuilderProps<T> = {
  quoteable: T;
  mode: T extends Lead ? 'lead' : 'event';
  // location_id: ModelID;
  readonly?: boolean;
  mutator: UseMutateFunction<
    T,
    unknown,
    T extends Lead ? LeadManageQuoteDTO : EventManageQuoteDTO,
    unknown
  >;
  children?: React.ReactNode;
};

const couponSchema = z.object({
  coupon_code: z.string().min(1, 'Required'),
});

export function QuoteBuilder<T extends Lead | Event>({
  quoteable,
  mode,
  readonly = false,
  mutator: mutate,
  children,
}: QuoteBuilderProps<T>) {
  const location = quoteable.franchise_id;
  const [taxRateEditMode, setTaxRateEditMode] = useState(false);
  const { data: services, isPending: servicesIsLoading } = useServices({
    location,
    search: { active: true, orderBy: 'asset_type_id', orderDir: 'asc' },
  });
  const { data: addons, isPending: addonsIsLoading } = useAddons({
    location,
    search: { active: true, orderBy: 'name', orderDir: 'asc' },
  });
  const { data: packages, isPending: packagesIsLoading } = usePackages({
    location,
    search: { active: true, orderBy: 'name', orderDir: 'asc' },
  });

  const couponForm = useForm<z.infer<typeof couponSchema>>({
    resolver: zodResolver(couponSchema),
    defaultValues: {
      coupon_code: '',
    },
  });

  const handleAddCoupon = (data: z.infer<typeof couponSchema>) => {
    addCoupon(data.coupon_code);
  };

  const activityLineItemSubTotal =
    quoteable.activities?.map((item) => item.total).reduce((a, b) => a + b, 0) ?? 0;

  const quoteLineItems = quoteable.line_items as LineItem[];

  const lineItems = quoteLineItems.filter(
    (item) => item.package_id === null && item.managed_by_package < 1,
  ) as T extends Lead ? LeadLineItem[] : EventLineItem[];

  const lineItemPacks = quoteable.line_items
    .map((item) => {
      if (item.package_id !== null) {
        item.sub_items = quoteLineItems.filter(
          (lineItem) => lineItem.managed_by_package === item.lineitem_id,
        );
      }

      return item;
    })
    .filter((item) => item.package_id !== null);

  const sendCommand = (command: string, data: any) => {
    if (true === readonly) return;

    const mutationCommand =
      'event_id' in quoteable
        ? { id: quoteable.id, quote_id: null, command, data }
        : { id: quoteable.id, quote_id: quoteable.primary_quote_id, command, data };

    mutate(mutationCommand, {
      onSettled: () => {
        if (
          command === 'AddActivity' ||
          command === 'UpdateActivity' ||
          command === 'RemoveActivity'
        ) {
          queryClient.invalidateQueries({ queryKey: ['availability'] });
          // queryClient.invalidateQueries({queryKey: [{ entity: 'list', location, scope: 'calendar-events' }]});
        }
        if (command === 'AddCoupon') {
          couponForm.reset();
        }
      },
    });
  };

  const addActivity = (service_id: ModelID) => {
    sendCommand('AddActivity', {
      service_id,
    });
  };

  const updateActivity = (activity_id: ModelID, data: any) => {
    sendCommand('UpdateActivity', {
      activity_id,
      ...data,
    });
  };

  const removeActivity = (activity_id: ModelID) => {
    sendCommand('RemoveActivity', {
      activity_id,
    });
  };

  const addLineItem = (addon_id: ModelID) => {
    sendCommand('AddLineItem', {
      addon_id,
    });
  };

  const updateLineItem = (lineitem_id: ModelID, data: any) => {
    sendCommand('UpdateLineItem', {
      lineitem_id,
      ...data,
    });
  };

  const removeLineItem = (lineitem_id: ModelID) => {
    sendCommand('RemoveLineItem', {
      lineitem_id,
    });
  };

  const addPackage = (package_id: ModelID) => {
    sendCommand('AddPackage', {
      package_id,
    });
  };

  const removePackage = (lineitem_id: ModelID) => {
    sendCommand('RemovePackage', {
      lineitem_id,
    });
  };

  const addCoupon = (coupon_code: string) => {
    sendCommand('AddCoupon', {
      coupon_code,
      franchise_id: location,
    });
  };

  const updateCoupon = (coupon_lineitem_id: ModelID, data: any) => {
    sendCommand('UpdateCoupon', {
      coupon_lineitem_id,
      ...data,
    });
  };

  const removeCoupon = (coupon_lineitem_id: ModelID) => {
    sendCommand('RemoveCoupon', {
      coupon_lineitem_id,
    });
  };

  const handleChangeTaxRate = (event: FocusEvent<HTMLInputElement>) => {
    sendCommand('ChangeTaxRate', {
      franchise_id: location,
      tax_rate: event.target.value,
    });
    setTaxRateEditMode(false);
  };

  const handleChangeOnlineBookable = (state: CheckedState) => {
    sendCommand('SetOnlineBookable', {
      franchise_id: location,
      is_bookable_online: !!state,
    });
  };

  return (
    <div className="@container flex flex-col divide-y space-y-2">
      <div className="flex flex-col @sm:flex-row items-center pt-3 pb-3">
        <div className="grow text-lg font-semibold">
          <span className="pr-2">Total: {formatMoney(quoteable.total ?? 0)}</span>
          {children}
        </div>

        {mode === 'lead' && (
          <span className="flex flex-row items-middle flex-shrink space-x-1 pr-2">
            <Checkbox
              name="is_bookable_online"
              checked={quoteable.is_bookable_online ?? true}
              onCheckedChange={(state) => handleChangeOnlineBookable(state)}
            />
            <span className="text-sm pt-1">OLB {quoteable.is_bookable_online ? 'On' : 'Off'}</span>
          </span>
        )}

        <div className="flex flex-row space-x-1">
          {servicesIsLoading && <Placeholder />}
          {!readonly && services && services.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm">
                  Add Activity
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-h-96 overflow-y-auto">
                {services.map((service, i) => {
                  return (
                    <DropdownMenuItem
                      key={`quote-service-${i}`}
                      onClick={() => addActivity(service.id)}
                      className="cursor-pointer"
                    >
                      {service.name}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          )}

          {addonsIsLoading && <Placeholder />}
          {!readonly && addons && addons.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm">
                  Add Addon
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-h-96 overflow-y-auto">
                {addons.map((addon, i) => {
                  return (
                    <DropdownMenuItem
                      key={`quote-addon-${i}`}
                      onClick={() => addLineItem(addon.id)}
                      className="cursor-pointer"
                    >
                      {addon.name}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          )}

          {packagesIsLoading && <Placeholder />}
          {!readonly && packages && packages.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm">
                  Add Package
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-h-96 overflow-y-auto">
                {packages.map((pack, i) => {
                  return (
                    <DropdownMenuItem
                      key={`quote-package-${i}`}
                      onClick={() => addPackage(pack.id)}
                      className="cursor-pointer"
                    >
                      {pack.name}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>

      {quoteable.activities.map((activity) => (
        <ActivityRow
          key={`activity-${activity.id}-${activity.updated_at}`}
          location_id={location}
          activity={activity}
          readonly={readonly}
          olbAdvancedDays={quoteable.franchise?.web_config?.olb_advance_days || 3}
          onSave={(data) => updateActivity(activity.id, data)}
          onDelete={() => removeActivity(activity.id)}
        />
      ))}

      <div className="flex flex-col space-y-3 pr-2 pt-4 pb-2">
        <div className="flex flex-row">
          <div className="grow" />
          <div className="text-left w-44 text-sm">Activity Subotal:</div>
          <div className="text-right w-20 text-sm">{formatMoney(activityLineItemSubTotal)}</div>
        </div>
      </div>

      <div className="pr-2 ">
        {lineItems.map((lineItem: LeadLineItem | EventLineItem) => (
          <LineItemRow
            key={`lineItem-${lineItem.id}`}
            lineItem={lineItem}
            readonly={readonly}
            onSave={(data) => updateLineItem(lineItem.id, data)}
            onDelete={() => removeLineItem(lineItem.id)}
          />
        ))}

        {lineItemPacks.map((lineItem) => (
          <LineItemRow
            key={`lineItem-package-${lineItem.id}`}
            lineItem={lineItem}
            readonly={readonly}
            onSave={(data) => updateLineItem(lineItem.id, data)}
            onDelete={() => removePackage(lineItem.id)}
          />
        ))}

        {quoteable.coupons.map((couponLineItem) => (
          <CouponRow
            key={`coupon-${couponLineItem.id}`}
            couponLineItem={couponLineItem}
            readonly={readonly}
            onSave={(data) => updateCoupon(couponLineItem.id, data)}
            onDelete={() => removeCoupon(couponLineItem.id)}
          />
        ))}
      </div>

      <div className="pl-3">
        <Form {...couponForm}>
          <form onSubmit={couponForm.handleSubmit(handleAddCoupon)}>
            <fieldset
              disabled={readonly}
              className="flex flex-row items-center space-x-2 space-y-3"
            >
              <div className="grow"> </div>
              <div>
                <FormInput
                  control={couponForm.control}
                  name="coupon_code"
                  placeholder="Coupon Code"
                />
              </div>
              <div className="w-18 pr-2">
                <Button size="lg" type="submit">
                  Add
                </Button>
              </div>
            </fieldset>
          </form>
        </Form>
      </div>

      <div className="flex flex-col space-y-3 pr-2 pt-4">
        <div className="flex flex-row">
          <div className="grow" />
          <div className="text-left w-44 text-sm">Subotal:</div>
          <div className="text-right w-20 text-sm">{formatMoney(quoteable.subtotal ?? 0)}</div>
        </div>
        <div className="flex flex-row">
          <div className="grow" />
          <div className="text-left w-44 text-sm">
            {!taxRateEditMode && (
              <button disabled={readonly} type="button" onClick={() => setTaxRateEditMode(true)}>
                Tax: {(quoteable.tax_rate * 100).toFixed(4)}%
              </button>
            )}
            {taxRateEditMode && (
              <span>
                Tax:
                <input
                  name="tax_rate"
                  className="block-inline w-16 mx-1 rounded-sm border-0 px-1 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  defaultValue={(quoteable.tax_rate * 100).toFixed(4)}
                  onBlur={handleChangeTaxRate}
                />
                %
              </span>
            )}
          </div>
          <div className="text-right w-20 text-sm">{formatMoney(quoteable.tax ?? 0)}</div>
        </div>
        <div className="flex flex-row">
          <div className="grow" />
          <div className="text-left w-44 font-semibold">Grand Total:</div>
          <div className="text-right w-20 font-semibold">{formatMoney(quoteable.total ?? 0)}</div>
        </div>
      </div>
    </div>
  );
}
