import { QuoteBuilder } from '@/components/QuoteBuilder';
import { Button } from '@/components/ui/elements/button';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { EventContext } from '@/features/events/contexts/EventContext';
import { Event } from '@/types';
import { cn } from '@/utils/format';
import { useContext, useState } from 'react';
import { EventEditForm } from '../Forms/EventEditForm';

export const EditTab = () => {
  const { event, manageQuote } = useContext(EventContext);
  const [readonly, setReadonly] = useState(true);

  if (!event) return <>Loading...</>;

  return (
    <div className="@container">
      <PolicyGate policy="events.update" forbiddenFallback={<InsufficientPermissions />}>
        <div
          className={cn(
            'flex flex-row justify-between mb-4 border rounded p-2 items-center',
            readonly
              ? 'bg-yellow-50 border-yellow-200 text-yellow-600'
              : 'bg-red-100 border-red-200 text-red-900',
          )}
        >
          {readonly ? (
            <h2 className="text-base font-semibold pl-2">Event #{event.id} is Locked</h2>
          ) : (
            <h2 className="text-base font-semibold pl-2">Editing Event #{event.id}</h2>
          )}

          <Button
            type="button"
            variant={readonly ? 'outline' : 'destructive'}
            onClick={() => {
              setReadonly(!readonly);
            }}
          >
            {readonly ? 'Unlock & Edit' : 'Lock'}
          </Button>
        </div>
        <div className="flex flex-col @2xl:flex-row space-x-2">
          <div className="pt-4 mr-10 w-full @2xl:w-2/5">
            <EventEditForm key={`eventedit-${event.event_id}`} event={event} readonly={readonly} />
          </div>
          <div className="grow @2xl:w-3/5">
            <QuoteBuilder<Event>
              key={`eventquote-${event.event_id}`}
              mode="event"
              quoteable={event}
              mutator={manageQuote}
              readonly={readonly}
            />
          </div>
        </div>
      </PolicyGate>
    </div>
  );
};
