import { useLockLead, useReleaseLead } from '@/api/leads/leads/updateLead';
import { Badge } from '@/components/ui/elements/badge';
import { Lead } from '@/types';
import { LockOpenIcon } from '@heroicons/react/24/outline';
import { LockClosedIcon } from '@heroicons/react/24/solid';

interface LockManagerBadgeProps {
  lead: Lead;
}

export const LockManagerBadge = ({ lead }: LockManagerBadgeProps) => {
  const initials = lead.locked_by_full_name
    ? lead.locked_by_full_name
        .split(' ')
        .map((n) => n[0])
        .join('')
    : null;

  const { mutate: lockLead } = useLockLead();
  const { mutate: unlockLead } = useReleaseLead();

  return (
    <>
      {!initials ? (
        <Badge
          size="sm"
          onClick={() => lockLead({ id: lead.id })}
          className="cursor-pointer hover:bg-green-100 hover:border-green-200 hover:text-green-600"
        >
          <LockOpenIcon className="w-4 h-4 mr-1" />
          {initials}
        </Badge>
      ) : (
        <Badge
          size="sm"
          onClick={() => unlockLead({ id: lead.id })}
          className="cursor-pointer hover:bg-orange-100 hover:border-orange-200 hover:text-orange-600"
        >
          <LockClosedIcon className="w-4 h-4 mr-1" />
          {initials}
        </Badge>
      )}
    </>
  );
};
