import { AclRole } from '@/features/auth/acl';
import type DecimalJS from 'decimal.js';

export interface ApiResponse<T> {
  data: T;
  meta: any;
}

export interface GetInfinitePagesInterface<T> {
  nextId: number;
  previousId: number;
  data: T;
  count: number;
}

export type SortDir = 'asc' | 'desc';

export type DateMode = null | '7day' | '30day' | 'last-month' | 'last-year' | 'wtd' | 'mtd' | 'ytd';

export type CompareMode = null | 'period' | 'year';

export type GroupMode = 'day' | 'week' | 'month' | 'year';

export type NumberFormat = 'currency' | 'short-currency' | 'percent' | 'decimal' | 'short-decimal';

export type Decimal = DecimalJS;

export enum LineTypeIntelligenceType {
  landline = 'landline',
  mobile = 'mobile',
  fixedVoip = 'fixedVoip',
  nonFixedVoip = 'nonFixedVoip',
  personal = 'personal',
  tollFree = 'tollFree',
  premium = 'premium',
  sharedCost = 'sharedCost',
  uan = 'uan',
  voicemail = 'voicemail',
  pager = 'pager',
  unknown = 'unknown',
}

export interface SearchParams<T = string, S = string> {
  filter?: T;
  orderBy?: S;
  orderDir?: SortDir;
  limit?: number;
  page?: number;
  include?: string;
}

export interface Dictionary<T> {
  [k: string]: T;
}

export type ModelID = number | string;

export type CompleteTransferDTO<T> = {
  id: ModelID;
  data: {
    map: any;
  };
  params?: T;
};

export type RejectTransferDTO<T> = {
  id: ModelID;
  params?: T;
};

export interface ShortUrl extends ModelBase {
  short_url_id: ModelID;
  code: string;
  final_url: string;
  clicks: string;
  readonly tracking_url: string;
}

export interface GamificationStats {
  level: number;
  score: number;
  level_progress: number;
  bookings: number;
  calls: number;
  contacts: number;
  emails: number;
  first_contacts: number;
  quotes: number;
  txts: number;
}

export interface CalendarEvent {
  id: string;
  cal_event_type: string;
  franchise_id: string;
  resourceId: string;
  allDay: string;
  start: string;
  end: string;
  title: string;
  className: string;
  backgroundColor: string;
  latitude: number;
  longitude: number;
  assetType: string;
  assetAbbr: string;
  assignmentIds?: string[];
}

export interface CalendarNote extends ModelBase {
  calendar_note_id: ModelID;
  created_at: string;
  updated_at: string;
  franchise_id: ModelID;
  title: string;
  start_at: string;
  end_at: string;
}

export interface ModelBase {
  id: ModelID;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export type LocationAccess = {
  franchise_id: ModelID;
  areaop: string;
  user_id: number;
  type_code: number;
  type: AclRole;
  rank: number;
  longitude: number;
  latitude: number;
};

export type User = ModelBase & {
  user_id: number;
  primary_franchise_id: number;
  active: boolean;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  phone: string;
  gender: string;
  avatar: string;
  is_callcenter: boolean;
  is_b2b: boolean;
  is_admin: boolean;
  super: boolean;

  permissions: string[];
  roles: string[];
  location_access: LocationAccess[];

  device_registrations: UserDevice[];
};

export interface UserDevice {
  device_id: string;
  device_token: string;
}

export interface UserPoint extends ModelBase, GamificationStats {
  user_id: ModelID;
  user: User;
}

export interface BaseNote extends ModelBase {
  message: string;
  type: string;
  private: boolean;
  createdby_user_id: number;
  createdby_user_name: string;
  createdby_user_avatar: string;

  created_by: User;
}

export interface ChangeLogResponse {
  changelog: ChangeLogNode[];
}

export interface ChangeLogNode {
  date: string;
  changes: ChangeLogEntry[];
}

export interface ChangeLogEntry {
  id: ModelID;
  created_at: string;
  updated_at: string;
  updatedby_user_id: ModelID;
  field_name: string;
  original_value: string;
  new_value: string;
  updated_by: User;
}

export interface PowerUp extends ModelBase {
  powerup_id: ModelID;
  active: boolean;
  name: string;
  description: string;
  price: number;
  price_mode: string;
}

export type FaqSections = 'general' | 'vg' | 'lt' | 'gel' | 'ztag' | 'fp' | 'wt' | 'gx';
export type FAQ = { question: string; answer: string };
export type FAQs = Record<FaqSections, FAQ[]>;

interface Quoteable extends ModelBase {
  model_type: 'event' | 'lead';
  is_bookable_online?: boolean | null;
}

export interface Activity extends ModelBase {
  activity_id: number;
  service_id: number;
  asset_id: number;
  name: string;
  description: string;
  arrive_at: string;
  start_at: string;
  end_at: string;
  depart_at: string;
  setup: number;
  base_duration: number;
  duration: number;
  teardown: number;
  base_price: number;
  addl_hour_price: number;
  taxable: boolean;
  total: number;
  price_locked: boolean;

  service: Service;
  asset: Asset;
}

export interface LineItem extends ModelBase {
  lineitem_id: number;
  package_id: number;
  managed_by_package: number;
  addon_id: number;
  name: string;
  description: string;
  quantity: number;
  unit_cost: number;
  subtotal: number;
  taxable: boolean;
  total: number;

  addon: Addon;
  package: Package;
  sub_items?: LineItem[];
}

export interface CouponLineItem extends ModelBase {
  coupon_id: ModelID;
  added_by_user_id?: ModelID | null;
  taxable: boolean;

  coupon: Coupon;
}

export type CustomerBankType = 'Seasonal' | 'Investment' | 'Relationship' | 'Key Influencer';

export interface Customer extends ModelBase {
  customer_id: ModelID;
  created_at: string;
  updated_at: string;
  is_organization: boolean;
  first_name: string;
  last_name: string;
  full_name: string;
  organization_name?: string | null;
  organization_type?: string | null;
  phone: string;
  phone_type?: LineTypeIntelligenceType | null;
  phone_valid?: 0 | 1 | null;
  phone_alt?: string | null;
  email: string;
  address?: string | null;
  address_line2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  billing_name?: string | null;
  billing_phone?: string | null;
  billing_email?: string | null;
  billing_address?: string | null;
  billing_address_line2?: string | null;
  billing_city?: string | null;
  billing_state?: string | null;
  billing_zipcode?: string | null;
  sms_event_updates: boolean;
  sms_lead_updates: boolean;
  do_not_email: boolean;
  last_booked: Date | string;
  stripe_customer_id: number;
  stripe_livemode: boolean;
  is_repeat_customer: boolean;
  referral_code: string | null;
  referral_campaign_id: ModelID | null;
  is_married?: boolean | null;
  spouse_name?: string | null;
  gender?: string | null;
  how_heard?: string | null;
  important_details?: string | null;
  where_met?: string | null;
  bank?: CustomerBankType;
  relationship_strength?: number | null;
  preferred_staff?: string | null;
  medical_notes?: string | null;
  school_name?: string | null;
  company_name?: string | null;
  linkedin_profile?: string | null;
  lastcontact_at?: string | null;
  lastcontact_by?: ModelID;
  followup_at?: string | null;
  has_bad_email?: boolean;

  events: Event[];
  leads: Lead[];
  notes: CustomerNote[];
  payments: Payment[];
  payment_methods: PaymentMethod[];
  vips: Vip[];
  last_contact_by?: User;
  lists: CustomerList[];
  surveys: EventSurvey[];
  referral_campaign?: ReferralCampaign | null;
}

export interface CustomerNote extends BaseNote {
  customer_note_id: ModelID;
  customer_id: ModelID;

  customer: Customer;
}

export interface CustomerList extends ModelBase {
  customer_list_id: ModelID;
  name: string;
  description?: string | null;
  franchise_id: ModelID;

  customers: Customer[];
}

export interface MessageTemplate extends ModelBase {
  name: string;
  message: string;
  category: string;
  franchise_id?: ModelID;
  asset_type_id?: ModelID | null;

  asset_type?: AssetType;
}

type CustomerMessageStatus =
  | 'draft'
  | 'queued'
  | 'sending'
  | 'sent'
  | 'delivered'
  | 'seen'
  | 'error';

type CustomerMessageMedia = {
  type: string;
  url: string;
};

export type CustomerMessage = ModelBase & {
  customer_message_id: ModelID;
  customer_id: ModelID;
  customer_contact_id: ModelID;
  sentby_user_id: ModelID;
  marketing_message_id: ModelID;
  notification_id: ModelID;
  platform_id: string;
  platform_name: string;
  group_message_id: string;
  status: CustomerMessageStatus;
  direction: 'inbound' | 'outbound';
  type: 'sms' | 'email';
  subject: string;
  message: string;
  media?: CustomerMessageMedia[];
  to: string;
  from: string;
  queued_at: Date;
  send_at: Date;
  sent_at: Date;
  delivered_at: Date;
  first_seen_at: Date;
  seen_at: Date;
  error_at: Date;
  error_message: string;
  retried_at: Date;
  retry_count: number;

  readonly sender_first_name: string;
  readonly sender_last_name: string;

  customer: Customer;
  sent_by: User;
} & (
    | {
        readonly contextable_type: null;
        readonly contextable_id: null;
        contextable: null;
      }
    | {
        readonly contextable_type: 'event';
        readonly contextable_id: ModelID;
        contextable: Event;
      }
    | {
        readonly contextable_type: 'lead';
        readonly contextable_id: ModelID;
        contextable: Lead;
      }
  );

export interface Vip extends ModelBase {
  vip_id: ModelID;
  customer_id: ModelID;
  first_name?: string | null;
  last_name?: string | null;
  gender?: string | null;
  age?: string | number | null;
  date_of_birth?: string | null;

  customer: Customer;
  events: Event[];
}

export interface PaymentMethod extends ModelBase {
  payment_method_id: ModelID;
  created_at: string;
  updated_at: string;
  customer_id: ModelID;
  stripe_card_id: string;
  exp_month: number;
  exp_year: number;
  brand: string;
  funding: string;
  last4: string;
  fingerprint: string;
  name: string;
  phone?: string | null;
  email?: string | null;
  address: string;
  address_line2?: string | null;
  city: string;
  state: string;
  zipcode: string;
  cvc_check: string;
  address_line1_check: string;
  address_zip_check: string;

  customer: Customer;

  payments: Payment[];
}

export interface Payment extends ModelBase {
  payment_id: ModelID;
  created_at: string;
  updated_at: string;
  customer_id: ModelID;
  payment_method_id: ModelID;
  event_id: ModelID;
  amount: number;
  gratuity: number;
  transaction_id: string;
  check_number: string;
  method: string;
  type: string;
  merchant_response: string;
  voided: boolean;

  customer: Customer;
  event: Event;
  payment_method: PaymentMethod;
}

export interface Depot extends ModelBase {
  depot_id: ModelID;
  franchise_id: ModelID;
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  description: string | null | undefined;

  assets: Asset[];
  franchise: any;
}

export interface Asset extends ModelBase {
  asset_id: ModelID;
  franchise_id: ModelID;
  asset_type_id: ModelID;
  depot_id: ModelID;
  vehicle_id: ModelID | null;
  in_service_after: string;
  sort_order: number;
  name: string;
  abbr: string | null | undefined;
  make: string | null | undefined;
  model: string | null | undefined;
  style: string | null | undefined;
  vin: string | null | undefined;
  notes: string | null | undefined;
  active: boolean;
  weekly_avg_capacity: number | null;

  color: string;
  color_alt: string;

  readonly full_name: string;

  asset_type: AssetType;
  blackouts: Blackout[];
  depot: Depot;
  franchise: any;
  schedules: MaintenanceSchedule[] | null;
  inspections: Inspection[] | null;
  vehicle: Vehicle | null;
}

export type BlackoutType =
  | null
  | ''
  | 'Repairs'
  | 'Service/Maintenance'
  | 'No Staff'
  | 'Schedule Closed'
  | 'Holiday'
  | 'Note'
  | 'Vehicle Reserved';

export interface Blackout extends ModelBase {
  blackout_id: ModelID;
  asset_id: ModelID;
  start_at: string;
  end_at: string;
  reason: string | null;
  type?: BlackoutType;

  asset: Asset;
}

export interface Event extends Quoteable {
  model_type: 'event';
  event_id: ModelID;
  created_at: string;
  updated_at: string;
  customer_id: ModelID;
  payment_method_id: ModelID;
  readonly franchise_id: ModelID;
  transferred_from: ModelID;
  transfer_pending: boolean;
  readonly lead_id: ModelID;
  event_type_id: ModelID;
  readonly bookedby_user_id: ModelID;
  status: string;
  start_at: string;
  end_at: string;
  waiting_since: string;
  readonly is_waiting: boolean;
  snooze_until: string | null;
  times_snoozed: number;
  readonly is_snoozed: boolean;
  name: string;
  contact_person?: string | null;
  phone?: string | null;
  address: string;
  address_line2?: string | null;
  city: string;
  state: string;
  zipcode: string;
  special_instructions?: string | null;
  latitude: number;
  longitude: number;
  taxable: boolean;
  tax_rate: number;
  readonly subtotal: number;
  readonly tax: number;
  readonly total: number;
  readonly invoice_num: string;
  guest_count: number | null;
  invite_count: number | null;
  readonly booked_at: string;
  readonly booked_online: boolean;
  readonly booked_by_name: string;
  readonly verified_at: string;
  readonly confirmed_at: string;
  readonly local_verified_at: string;
  readonly invites_sent_at: string;
  readonly thankyou_sent_at: string;
  readonly balance_due: number;
  readonly gratuity_paid: number;

  assets: Asset[];
  booked_by: User;
  customer: Customer;
  event_type: EventType;
  franchise: Franchise;
  lead: Lead;
  activities: EventActivity[];
  line_items: EventLineItem[];
  coupons: EventCouponLineItem[];
  notes: EventNote[];
  payments: Payment[];
  transferred_from_franchise?: Location | null;
  // payment_method: PaymentMethod;
  survey: EventSurvey | null;
  vips: Vip[];
}

export interface EventActivity extends Activity {
  event_id: ModelID;
  staff: User[];
}

export type EventLineItem = LineItem & {
  event_id: ModelID;
};

export type EventCouponLineItem = CouponLineItem & {
  event_id: ModelID;
};

export interface EventNote extends BaseNote {
  event_note_id: ModelID;
  event_id: ModelID;

  event: Event;
}

export interface EventSurvey extends ModelBase {
  survey_id: ModelID;
  event_id: ModelID;
  franchise_id: ModelID;
  completed_at: string | null;
  digest_sent_at: string | null;
  recommend: number | null;
  comments: string | null;
  may_contact: boolean | null;
  answer_1: number | null;
  answer_2: number | null;
  answer_3: number | null;
  answer_4: number | null;
  answer_5: number | null;
  answer_6: number | null;
  answer_7: number | null;
  answer_8: number | null;

  event: Event;
}

export interface Lead extends Quoteable {
  model_type: 'lead';
  lead_id: ModelID;
  created_at: string;
  updated_at: string;
  customer_id: ModelID;
  franchise_id: ModelID;
  primary_quote_id: ModelID | null;
  transferred_from: ModelID | null;
  transfer_pending: boolean;
  event_type_id: ModelID;
  firstcontact_by: number | null;
  owned_by: User | null;
  locked_by: ModelID | null;
  lock_timeout_at: string | null;
  createdby_user_id: ModelID | null;
  is_priority: boolean;
  completion: number;
  completion_class: string;
  readonly full_name: string;
  first_name: string | null;
  last_name: string | null;
  phone?: string | null;
  email?: string | null;
  name: string | null;
  address: string;
  address_line2?: string | null;
  city: string;
  state: string;
  zipcode: string;
  special_instructions?: string | null;
  event_day_contact?: string | null;
  event_day_phone?: string | null;
  latitude: number;
  longitude: number;
  firstcontact_at: string | null;
  lastcontact_at: string | null;
  waiting_since: string | null;
  readonly is_waiting: boolean;
  snooze_until: string | null;
  times_snoozed: number;
  readonly is_snoozed: boolean;
  followup_at?: string | null;
  closed_at: string | null;
  date_wanted: string | null;
  time_wanted?: string | null;
  length_wanted: number | null;
  guest_count?: number | string | null;
  invite_count?: number | string | null;
  time_to_call: string | null;
  best_method: string | null;
  activity_interest: string[];
  promo_code?: string | null;
  how_heard?: string | null;
  how_heard_other?: string | null;
  pipeline: string | null;
  status: string;
  close_reason: string | null;
  quote_sent_at: string | null;
  subtotal: number | null;
  taxable: boolean;
  tax: number | null;
  total: number | null;
  source_campaign: string | null;
  source_raw: string | null;
  source_campaign_key: string | null;
  condition: string | null;
  source_platform: string | null;

  readonly owned_by_full_name: string | null;
  readonly locked_by_full_name: string | null;
  readonly latest_message?: string | null;
  readonly franchise_areaop: string;
  readonly franchise_booking_name: string;
  readonly franchise_booking_phone: string;
  is_powerups: boolean;
  readonly is_in_par: boolean;
  readonly is_tier_two_sales: boolean;
  estimated_distance: number;
  zone: string;
  tax_rate: number;

  // zip: Zipcode;
  franchise: Location;
  transferred_from_franchise?: Location | null;
  customer?: Customer | null;
  // created_by: User;
  event_type: EventType;
  notes: LeadNote[];
  vips: Vip[];
  // package: Package[];
  activities: LeadActivity[];
  line_items: LeadLineItem[];
  coupons: LeadCouponLineItem[];
  // fee: LeadLineItem[];
  // addon: LeadLineItem[];
  quotes?: Quote[] | null;
  primary_quote?: Quote | null;
}

export interface Quote extends ModelBase {
  lead_quote_id: ModelID;
  lead_id: ModelID;
  readonly createdby_user_id: ModelID | null;
  is_bookable_online: boolean;
  expires_at: string | null;
  customer_message: string | null;
  internal_notes: string | null;
  readonly data: null | {
    subtotal: number;
    tax: number;
    total: number;
    activities: LeadActivity[];
    line_items: LeadLineItem[];
    packages: LeadLineItem[];
    coupons: LeadCouponLineItem[];
  };
  readonly publishable_key: string | null;
  readonly is_expired: boolean;

  created_by: User | null;
  lead: Lead;
}

export interface LeadNote extends BaseNote {
  lead_note_id: ModelID;
  lead_id: ModelID;

  lead: Lead;
}

export interface LeadActivity extends Activity {
  lead_id: ModelID;
  asset_type_id: ModelID;
}

export type LeadLineItem = LineItem & {
  lead_id: ModelID;
};

export type LeadCouponLineItem = CouponLineItem & {
  lead_id: ModelID;
};

export interface Franchise extends ModelBase {
  franchise_id: ModelID;
  created_at: string;
  updated_at: string;
  active: boolean;
  name: string;
  display_name: string;
  areaop: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  internal_phones?: string | null;
  internal_emails?: string | null;
  invoice_num: number;
  stripe_account_id: ModelID | null;
  zip_based_tax_rate: boolean;
  tax_rate: number;
  deposit_mode: 'percent' | 'fixed';
  deposit_amount: number;
  booking_email: string;
  booking_phone: string;
  booking_name: string;
  web_config: any;
  faqs: any;
  callcenter_optout: boolean;
  local_transfer_number: string | null;
  cancellation_policy: string;
  callcenter_instructions: string;
  cci_sheet_path: string | null;
  avoid_tolls: boolean;
  facebook_url?: string | null;
  yelp_url?: string | null;
  googleplus_url?: string | null;
  twitter_url?: string | null;
  pinterest_url?: string | null;
  instagram_url?: string | null;
  linkedin_url?: string | null;
  review_target: 'google' | 'yelp' | 'fb' | 'gthq';
  traffic_model: 'best_guess' | 'pessimistic' | 'optimistic';
  website_url: string;
  instagram_token: string | null;

  uses_automated_confirmations: boolean;
  confirmation_days_in_advance: number;
  workplace_group_id?: string | null;
  mobile_marketing_tier: string;

  coupons_are_taxable: boolean;
  tremendous_active: boolean;

  review_target_url?: ShortUrl;
  // addons: Addon[];
  assets: Asset[];
  depots: Depot[];
  events: Event[];
  leads: Lead[];
  // notifications: FranchiseNotification[];
  // franchiseUsers: FranchiseUser[];
  users: User[];
  packages: Package[];
  // price_rules: PriceRule[];
  powerups: PowerupSubscription[];
  referral_campaigns: ReferralCampaign[];
  services: Service[];
  addons: Addon[];
  // stripe_account: StripeAccount;
  // travel_charges: TravelCharge[];
  // zipcodes: Zipcode[];
}

export interface PowerupSubscription extends ModelBase {
  franchise_powerups_id: ModelID;
  franchise_id: ModelID;
  powerup_id: ModelID;
  start_at: string;
  end_at: string;
  price: number;
  notes: string;
  active: boolean;

  powerup: PowerUp;
}

export interface TravelCharge extends ModelBase {
  travelcharge_id: ModelID;
  franchise_id: ModelID;
  asset_type_id: ModelID | null;
  asset_id: ModelID | null;
  charge: number | null;
  name: string;
  notes: string | null;
  raw_geometry?: any[];
  type: 'travel' | 'exclusion' | 'refer';
  color: string;
}

export interface LocationUserAccess extends ModelBase {
  franchises_users_id: ModelID;
  franchise_id: ModelID;
  user_id: ModelID;
  type_code: number | string;
  type: string;
  rank: number;
  workplace_access: boolean;

  readonly first_name: string;
  readonly last_name: string;
  readonly full_name: string;
  readonly phone: string;
  readonly email: string;
  readonly active: boolean;

  user: User;
}

export interface AlternativeContact extends ModelBase {
  franchise_alt_contact_id: ModelID;
  franchise_id: ModelID;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;

  readonly full_name: string;
}

export interface Notification extends ModelBase {
  franchise_notification_id: ModelID;
  franchise_id: ModelID;
  type: string;
  by_email: boolean;
  by_sms: boolean;
  by_slack: boolean;
  by_desktop: boolean;
  by_push: boolean;
  notifiable_id: number;
  notifiable_type: string;

  notifiable: User | AlternativeContact;
}

export type Location = Franchise;

export interface Service extends ModelBase {
  service_id: ModelID;
  franchise_id: ModelID;
  asset_type_id: ModelID;
  active: boolean;
  taxable: boolean;
  name: string;
  description: string | null;
  marketing_description: string | null;
  marketing_fineprint: string | null;
  durations: any;
  setup: number;
  teardown: number;
  timeslots: any;
  days_allowed: string[];
  event_type_ids: number[];
  addl_hour_price: number;
  minimum_age: number | null;
  capacity: number | null;
  max_capacity: number | null;

  asset_type: AssetType;
  franchise: Franchise;
  event_types: EventType[];
}

export interface Addon extends ModelBase {
  addon_id: ModelID;
  franchise_id: ModelID;
  product_id: ModelID;
  active: boolean;
  online_orders: boolean;
  dropship: boolean;
  name: string;
  description?: string | null;
  cost: number;
  price: number;
  taxable: boolean;

  franchise: Franchise[];
  product: Product;

  // pivot: any;
}

export interface Package extends ModelBase {
  package_id: ModelID;
  franchise_id: ModelID;
  active: boolean;
  online_orders: boolean;
  publish: boolean;
  taxable: boolean;
  dropship: boolean;
  name: string;
  description: string | null;
  marketing_description: string | null;
  cost: number;
  price: number;

  addons: Addon[];
  franchise: Franchise;
}

export interface AssetType extends ModelBase {
  asset_type_id: ModelID;
  name: string;
  abbr: string;
  description: string;

  color: string;
  color_alt: string;
}

export interface EventType extends ModelBase {
  event_type_id: ModelID;
  name: string;
  description: string;
}

export interface Product extends ModelBase {
  product_id: ModelID;
  active: boolean;
  dropship: boolean;
  name: string;
  description: string;
  retail_price: number;
  wholesale_price: number;
  recommended_price: number;
  type: string;
}

export interface Motd extends ModelBase {
  title: string;
  content: string;
}

export interface Coupon extends ModelBase {
  franchise_id: ModelID;
  batch_id?: string | null;
  type: string;
  use_limit: number;
  uses: number;
  code: string;
  can_combine: boolean;
  assigned_at?: string | null;
  referring_customer_id?: ModelID | null;
  used_at?: string | null;
  expires_at?: string | null;
  value: number;
  taxable: boolean;
}

export interface ReferralReward extends ModelBase {
  referral_reward_id: ModelID;
  customer_id: ModelID;
  event_id: ModelID;
  franchise_id: ModelID;
  referral_campaign_id: ModelID;
  tremendous_order_id?: string | null;
  value: number;
  status?: string;
  earned_at?: string | null;
  voided_at?: string | null;
  approved_at?: string | null;
  fulfilled_at?: string | null;

  customer: Customer;
  event: Event;
  franchise: Franchise;
  referral_campaign: ReferralCampaign;
}

export interface ReferralCampaign extends ModelBase {
  referral_campaign_id: ModelID;
  franchise_id: ModelID;
  tremendous_campaign_id?: string | null;
  tremendous_active: boolean;
  campaign_active: boolean;
  name: string;
  active: boolean;
  default: boolean;
  reward_value: number;
  coupon_value: number | null;
  offer_description: string;

  franchise?: Franchise;
}

export type Pin = ModelBase & {
  user_pin_id: ModelID;
  user_id: ModelID;
  user: User;
  pinned_id: ModelID;
  note?: string | null;
} & (
    | {
        pinned_type: 'lead';
        pinned: Lead;
      }
    | {
        pinned_type: 'event';
        pinned: Event;
      }
    | {
        pinned_type: 'customer';
        pinned: Customer;
      }
  );

export interface DirectoryContact {
  name: string;
  email: string;
  phone: string;
  title: string;
  calendar_link?: string;
  franchise?: string | null;
  extra?: string | null;
}

export interface Directory {
  licensing: DirectoryContact[];
  owners: DirectoryContact[];
}

export interface Vehicle extends ModelBase {
  vehicle_id: ModelID;
  createdby_user_id: ModelID | null;
  franchise_id: ModelID;
  depot_id: ModelID | null;
  name: string | null;
  vin: string | null;
  make: string;
  model: string;
  year: string;
  license_plate: string | null;
  license_plate_state: string | null;
  registration_expires_at: string | null;
  insurance_expires_at: string | null;
  inspection_expires_at: string | null;
  fuel_type: string | null;

  readonly full_name: string;
  readonly last_mileage: number;

  assets: Asset[];
  attachments: Attachment[];
  depot: Depot | null;
  franchise: Franchise;
  mileage: VehicleMileage[];
  schedules: MaintenanceSchedule[] | null;
  inspections: Inspection[] | null;
}

export interface VehicleMileage extends ModelBase {
  vehicle_mileage_id: ModelID;
  createdby_user_id: ModelID | null;
  vehicle_id: ModelID;
  mileage: number;
  recorded_at: string;

  vehicle: Vehicle;
}

export interface MaintenanceSchedule extends ModelBase {
  maintenance_schedule_id: ModelID;
  name: string;
  description: string | null;
  days_interval: number | null;
  usage_interval: number | null;
  usage_unit: string | null;
  readonly last_performed_at: string | null;
  readonly last_performed_usage: number | null;
  readonly is_past_due: boolean;
  readonly is_past_due_time: boolean;
  readonly is_past_due_usage: boolean;
  readonly next_due_date: string | null;
  readonly next_usage_count: number | null;
  readonly maintainable_id: string;
  readonly maintainable_type: string;

  maintainable: Vehicle | Asset;
  next_task: MaintenanceTask | null;
  tasks: MaintenanceTask[];
}

export interface MaintenanceTask extends ModelBase {
  maintenance_task_id: ModelID;
  maintenance_schedule_id: ModelID | null;
  name: string;
  description: string | null;
  due_at: string;
  scheduled_at: string | null;
  completed_at: string | null;
  usage_reading: number | null;

  readonly is_past_due: boolean;
  readonly is_completed_late: boolean;

  schedule: MaintenanceSchedule | null;
  attachments: Attachment[] | null;
}

export interface Attachment extends ModelBase {
  attachment_id: ModelID;
  createdby_user_id: ModelID | null;
  name: string;
  path: string;
  type: string;
  readonly download_link: string;
  readonly attachable_id: ModelID;
  readonly attachable_type: string;
}

export interface Inspection extends ModelBase {
  inspection_id: ModelID;
  name: string;
  description: string | null;
  rrule: string;
  usage_unit: string | null;
  maintainable_id: ModelID;
  maintainable_type: string;

  readonly rule_for_human: string;
  readonly is_past_due: boolean;
  readonly last_expected_date: string;
  readonly next_due_date: string;
  readonly last_performed_at: string | null;

  maintainable: Vehicle | Asset;
  logs: InspectionLog[];
}

export interface InspectionLog extends ModelBase {
  inspection_log_id: ModelID;
  inspection_id: ModelID;
  name: string;
  due_at: string;
  completed_at: string | null;
  usage_reading: number | null;
  notes: string | null;

  readonly is_past_due: boolean;
  readonly is_completed_late: boolean;

  inspection: Inspection;
  attachments: Attachment[] | null;
}
